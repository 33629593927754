import React from "react";
import cx from "classnames";
import {createUseStyles} from "react-jss";
import {Typography} from "../common/Typography";

const useStyles = createUseStyles((theme) => ({
    alternative: {
        display: "flex",
        width: "588px",
        height: hasMedia => hasMedia ? "137px" : "289px",
        borderRadius: "32px",
        cursor: "pointer",
        justifyContent: "flex-start",
        gap: 48,
        position: "relative",
        boxSizing: "border-box",
        "&:hover": {
            border: "2px solid white",
            borderRadius: "32px",
        },
        "&.selected": {
            border: "6px solid white",
            borderRadius: "32px",
        },
        "&.optionA": {
            backgroundColor: theme.colors.optionA,
            marginRight: "12px",
            marginBottom: "12px",
            color: "white",
            "@media (max-width: 1200px)": {
                margin: "12px 0",
            },
        },
        "&.optionB": {
            backgroundColor: theme.colors.optionB,
            marginLeft: "12px",
            marginBottom: "12px",
            color: "white",
            "@media (max-width: 1200px)": {
                margin: "12px 0",
            },
        },
        "&.optionC": {
            backgroundColor: theme.colors.optionC,
            marginRight: "12px",
            marginTop: "12px",
            color: "white",
            "@media (max-width: 1200px)": {
                margin: "12px 0",
            },
        },
        "&.optionD": {
            backgroundColor: theme.colors.optionD,
            marginLeft: "12px",
            marginTop: "12px",
            color: "white",
            "@media (max-width: 1200px)": {
                margin: "12px 0",
            },
        },

        "@media (max-width: 1200px)": {
            margin: "0 12px",
            width: "100%",
            height: "100%",
        },
    },
    content: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowWrap: "break-word",
        overflow:"hidden"
    },
    optionText: {
        textTransform: "uppercase",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        marginLeft: 16,

        "@media (max-width: 1200px)": {
            top: "50%",
            transform: "translateY(-50%)",
            left: "10px",
        },
    },
}));

export const Alternative = ({answer, option, onClick, selected, hasMedia}) => {
    const classes = useStyles({
        hasMedia: hasMedia
    })
    console.debug({option})
    return (
        <div
            key={option}
            className={cx(
                classes.alternative,
                `option${option.toUpperCase()}`,
                {
                    selected,
                }
            )}
            onClick={onClick}
        >
            <Typography
                tag="span"
                size="xl2"
                bold
                className={classes.optionText}
            >
                {option}
            </Typography>
            <Typography
                tag="span"
                bold
                size={hasMedia ? "xl4" : "xl8"}
                className={classes.content}>
                {answer.content}
            </Typography>
        </div>
    );
};
