import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    quizContentContainer: {
        maxWidth: "32rem",
        width: "100%",
	overflowY: "auto"
    }
})
export const QuizContentContainer = ({children}) => {
    const classes = useStyles()
    return (
        <div
            className={classes.quizContentContainer}
        >
            {children}
        </div>
    )
}
