import React from "react";
import {createUseStyles} from "react-jss";

import {Alternative} from "./Alternative";
import {Typography} from "../common/Typography";
import {useCurrentQuestion} from "./useCurrentQuestion";
import {useNavigation} from "../navigation/useNavigation";
import {useCurrentAnswer} from "../answer/useCurrentAnswer";
import {QuestionMedia} from "./QuestionMedia";

const useStyles = createUseStyles(({ fontSize, lineHeight }) => ({
    question: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        maxWidth: "1200px",
        flexGrow: 1,
        overflow: "hidden",
        width: "100%",
        "@media (max-width: 1200px)": {
            width: "90%",
        },
        gap: "20px"
    },
    questionText: {
        textAlign: "center",
        minHeight: "90px",
        "@media (max-width: 375px)": {
            fontSize: fontSize.xl,
            lineHeight: lineHeight.leadingRelaxed,
        },
    },
    alternatives: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        width: "100%",
    },
}))

export const Question = () => {
    const classes = useStyles();
    const alternatives = ["a", "b", "c", "d"];
    const question = useCurrentQuestion()
    const {
        answerUniqueId,
        selectAnswer
    } = useCurrentAnswer()
    const {
        questionIndex
    } = useNavigation()


    return (
        <div className={classes.question}>
            <Typography
                tag="h1"
                size="xl4"
                bold
                className={classes.questionText}
            >
                {questionIndex + 1}. {question.content}
            </Typography>
            <QuestionMedia
               media={question.breadcrumbMedia || question.media}
            />
            <div className={classes.alternatives}>
                {question.answers
                    .filter(a => a.active)
                    .map((answer, idx) => (
                        <Alternative
                            key={answer.uniqueId}
                            answer={answer}
                            option={alternatives[idx]}
                            onClick={() => selectAnswer(answer.uniqueId)}
                            selected={answerUniqueId === answer.uniqueId}
                            hasMedia={question.breadcrumbMedia || question.media !== null}
                        />
                ))}
            </div>
        </div>
    );
};
